import React, { useState, useEffect, useRef } from 'react';
import './Multiselect.css'; // Import the CSS file for styling

const MultiSelect = ({ options, placeholder, selectedOptions, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userInput, setUserInput] = useState('');
    const dropdownRef = useRef(null); // Ref for the dropdown menu

    const support_group_labels = [
        'Lesbian, gay, bisexual, transgender, or queer/questioning (LGBTQ)', 'Veterans', 'Active duty military',
        'Members of military families', 'Criminal justice (other than DUI/DWI)/Forensic clients',
        'Clients with co-occurring mental and substance use disorders', 'Clients with HIV or AIDS',
        'Clients who have experienced intimate partner violence, domestic violence', 'Clients who have experienced trauma',
        'Persons with traumatic brain injury (TBI)', "Persons with Alzheimer's or dementia", 'Persons with eating disorders',
        'Persons experiencing first-episode psychosis', 'Persons with post-traumatic stress disorder (PTSD)',
        'Children/adolescents with serious emotional disturbance (SED)', 'Persons 18 and older with serious mental illness (SMI)', 'Young adults',
        'Clients who have experienced intimate partner violence, domestic violence', 'Clients with HIV or AIDS', 'Adult men', 'Adult women', 'Pregnant/postpartum women',
        'Adolescents', 'Criminal justice (other than DUI/DWI)/Forensic clients', 'Seniors or older adults', 'Clients who have experienced sexual abuse'
    ];

    const openDropdown = () => {
        setIsDropdownOpen(true);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleUserInputChange = (event) => {
        const value = event.target.value;
        setUserInput(value);
        setSearchTerm(value); // Set search term to user input
        openDropdown(); // Ensure the dropdown opens when input changes
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const trimmedInput = userInput.trim();
            if (!selectedOptions.includes(trimmedInput)) {
                const matchedOption = options.find(option => option.toLowerCase() === trimmedInput.toLowerCase());
                if (matchedOption && !selectedOptions.includes(matchedOption)) {
                    onSelect([...selectedOptions, matchedOption]); // Add the matched option as a selected option
                    setUserInput(''); // Clear the input field
                    setSearchTerm(''); // Clear search term
                    closeDropdown(); // Close dropdown after selection
                }
            }
        }
    };

    const handleSelectOption = (option) => {
        if (!selectedOptions.includes(option)) {
            onSelect([...selectedOptions, option]); // Pass the updated selected options to the parent component
            setSearchTerm(''); // Clear search term after selection
            setUserInput(''); // Clear the input field
            closeDropdown(); // Close the dropdown after selecting an option
        }
    };

    const handleRemoveOption = (option) => {
        const updatedOptions = selectedOptions.filter((item) => item !== option);
        onSelect(updatedOptions); // Pass the updated selected options to the parent component
    };

    // Filter options that are not selected yet and match the current search term
    const searchedOptions = options
        .filter((option) => !selectedOptions.includes(option))
        .filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()));

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Close the dropdown if the click occurs outside of the dropdown or input box
                closeDropdown();
            }
        };

        // Add event listener to listen for clicks on the document body
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="multi-select">
            <div className="selected-options">
                {selectedOptions.map((option) => (
                    <div key={option} className="selected-option">
                        {support_group_labels.includes(option) ?
                            (<span>{option} special program/group</span>) :
                            (<span>{option}</span>)
                        }
                        <button onClick={() => handleRemoveOption(option)}>×</button>
                    </div>
                ))}
                <input
                    type="text"
                    value={userInput}
                    onChange={handleUserInputChange}
                    onFocus={openDropdown} // Open the dropdown when input is focused
                    onKeyPress={handleKeyPress} // Listen for keypress event
                    placeholder={placeholder}
                />
            </div>
            {isDropdownOpen && searchedOptions.length > 0 && (
                <div className="dropdown" ref={dropdownRef}>
                    {searchedOptions
                        .filter(option => option !== 'Female' && option !== 'Male')
                        .map((option) => (
                            <div
                                key={option}
                                className="dropdown-option"
                                onClick={() => handleSelectOption(option)}
                            >
                                {support_group_labels.includes(option) ?
                                    (<span>{option} special program/group</span>) :
                                    (<span>{option}</span>)
                                }
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
