import React, { useState, useRef, useEffect } from 'react';
import './State_Multiselect.css'; // Import the CSS file for styling

const State_MultiSelect = ({ placeholder, onSelect }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(placeholder);
    const dropdownRef = useRef(null);

    const StateOptions = [
        'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 
        'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 
        'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 
        'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 
        'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 
        'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
    ];

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        onSelect(option); // Pass the selected option to the parent component
        setIsDropdownOpen(false); // Close the dropdown after selecting an option
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="state-single-select" ref={dropdownRef}>
            <div className="state-selected-option" onClick={toggleDropdown}>
                {selectedOption || placeholder}
            </div>
            {isDropdownOpen && (
                <div className="state-dropdown">
                    {StateOptions.filter(option => option !== selectedOption).map((option) => (
                        <div
                            key={option}
                            className="state-dropdown-option"
                            onClick={() => handleSelectOption(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default State_MultiSelect;
