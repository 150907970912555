import React, { useState, useEffect } from 'react';
import './App.css';
import MultiSelect from './Components/Multiselect'; 
import County_MultiSelect from './Components/County_Multiselect'; 
import State_MultiSelect from './Components/State_Multiselect'; 
import TopArrowButton from './TopArrowButton';


function NewApp() {
    const [countySelection, setCountySelection] = useState(['San Francisco']);
    const [stateSelection, setStateSelection] = useState('CA');
    const [userInput, setUserInput] = useState('');
    const [geminiClassifierResults, setGeminiClassifierResults] = useState({});
    
    const [searchResults, setSearchResults] = useState({});
    const [facilitiesSearchResults, setFacilitiesSearchResults] = useState({});
    const [resourcesSearchResults, setResourcesSearchResults] = useState({});
    
    const [isLoading, setIsLoading] = useState(false);

    const [countyOptionList, setCountyOptionList] = useState(['Alameda', 'Alpine', 'Amador', 'Butte', 'Calaveras', 'Colusa', 'Contra Costa', 'Del Norte', 'El Dorado', 'Fresno', 'Glenn', 'Humboldt', 'Imperial', 'Inyo', 'Kern', 'Kings', 'Lake', 'Lassen', 'Los Angeles', 'Madera', 'Marin', 'Mariposa', 'Mendocino', 'Merced', 'Mono', 'Monterey', 'Napa', 'Nevada', 'Orange', 'Placer', 'Plumas', 'Riverside', 'Sacramento', 'San Benito', 'San Bernardino', 'San Diego', 'San Francisco', 'San Joaquin', 'San Luis Obispo', 'San Mateo', 'Santa Barbara', 'Santa Clara', 'Santa Cruz', 'Shasta', 'Sierra', 'Siskiyou', 'Solano', 'Sonoma', 'Stanislaus', 'Sutter', 'Trinity', 'Tulare', 'Tuolumne', 'Ventura', 'Yolo', 'Yuba']);
    // const allCACounties = ['Alameda', 'Alpine', 'Amador', 'Butte', 'Calaveras', 'Colusa', 'Contra Costa', 'Del Norte', 'El Dorado', 'Fresno', 'Glenn', 'Humboldt', 'Imperial', 'Inyo', 'Kern', 'Kings', 'Lake', 'Lassen', 'Los Angeles', 'Madera', 'Marin', 'Mariposa', 'Mendocino', 'Merced', 'Mono', 'Monterey', 'Napa', 'Nevada', 'Orange', 'Placer', 'Plumas', 'Riverside', 'Sacramento', 'San Benito', 'San Bernardino', 'San Diego', 'San Francisco', 'San Joaquin', 'San Luis Obispo', 'San Mateo', 'Santa Barbara', 'Santa Clara', 'Santa Cruz', 'Shasta', 'Sierra', 'Siskiyou', 'Solano', 'Sonoma', 'Stanislaus', 'Sutter', 'Trinity', 'Tulare', 'Tuolumne', 'Ventura', 'Yolo', 'Yuba']



    const [selectedGeminiResults, setSelectedGeminiResults] = useState([]);
    // const [scrollToTags, setScrollToTags] = useState(false);
    const [showNoInputMessage, setShowNoInputMessage] = useState(false);

    const [showFacilities, setShowFacilities] = useState(true);
    const [showResources, setShowResources] = useState(true);

    


    const label_mapping = {
      "MENTAL HEALTH TREATMENT": {'critical': 0, 'facilities': ['Mental health treatment'], 'resources': []},
      "SUBSTANCE USE TREATMENT": {'critical': 0, 'facilities': ['Substance use treatment'], 'resources': []},
      "FOR CHILDREN OR TEENAGERS": {'critical': 0, 'facilities': ['Children/Adolescents'], 'resources': ['CHILDREN & TEENS','CRISIS SUPPORT - TEENS','TEEN CRISIS SUPPORT']},
      "FOR YOUNG ADULTS": {'critical': 0, 'facilities': ['Young Adults'], 'resources': []},
      "FOR ADULTS": {'critical': 0, 'facilities': ['Adults'], 'resources': []},
      "FOR SENIORS OR ELDERLY": {'critical': 0, 'facilities': ['Seniors'], 'resources': ['AGING POPULATIONS']},
      "FOR FEMALE": {'critical': 0, 'facilities': ['Female'], 'resources': []},
      "FOR MALE": {'critical': 0, 'facilities': ['Male'], 'resources': []},
      'FOR PARENTS OF CHILDREN & TEENAGERS': {'critical': 0, 'facilities': [], 'resources': ['PARENTS OF CHILDREN & TEENS']},
      'AFRICAN AMERICAN COMMUNITY': {'critical': 0, 'facilities': [], 'resources': ['AFRICAN AMERICAN COMMUNITY']},
      'ASIAN AMERICAN COMMUNITY': {'critical': 0, 'facilities': [], 'resources': ['ASIAN AMERICAN COMMUNITY']},
      'LATINX COMMUNITY': {'critical': 0, 'facilities': [], 'resources': ['LATINX COMMUNITY']},
      'MUSLIM AMERICAN COMMUNITY': {'critical': 0, 'facilities': [], 'resources': ['MUSLIM AMERICAN COMMUNITY']},
      'NATIVE AMERICAN/INDIGENOUS COMMUNITY': {'critical': 0, 'facilities': [], 'resources': ['NATIVE AMERICAN/INDIGENOUS COMMUNITY']}, 
      "24-HOUR HOSPITAL INPATIENT CARE": {'critical': 0, 'facilities': ['Hospital inpatient/24-hour hospital inpatient'], 'resources': []},
      "OUTPATIENT CARE":{'critical': 0, 'facilities': ['Outpatient'], 'resources': []},
      "RESIDENTIAL/24-HOUR RESIDENTIAL CARE":{'critical': 0, 'facilities': ['Residential/24-hour residential'], 'resources': []},
      "TRANSITIONAL HOUSING/HALFWAY HOUSE/SOBER HOME": {'critical': 0, 'facilities': ['Transitional housing, halfway house, or sober home'], 'resources': []},
      "INDIVIDUAL PSYCHOTHERAPY OR COUNSELING": {'critical': 0, 'facilities': ['Individual counseling'], 'resources': ['PSYCHIATRISTS','PSYCHOTHERAPY/TALK THERAPY','PSYCHOLOGISTS','ONLINE MENTAL HEALTH SCREENING']},
      'ONLINE AND TELEMENTAL HEALTH':{'critical': 0, 'facilities': ['Telemedicine/telehealth therapy'], 'resources': ['ONLINE MENTAL HEALTH SCREENING','ONLINE AND TELEMENTAL HEALTH','ONLINE & TELECONFERENCE SUPPORT']},
      'CRISIS SUPPORT':{'critical': 0, 'facilities': [], 'resources': ['CRISIS/EMERGENCY MENTAL HEALTH SERVICES','PREPARING FOR A CRISIS','SUICIDAL IDEATION CRISIS','URGENT MEDICATION NEEDS']},
      'SUICIDAL IDEATION CRISIS':{'critical': 0, 'facilities': ['Suicide prevention services'], 'resources': ['SUICIDE','SUPPORT GROUPS FOR SUICIDAL IDEATION & LOSS FROM SUICIDE','SUPPORTING SOMEONE WITH SUICIDAL THOUGHTS','CRISIS/EMERGENCY MENTAL HEALTH SERVICES','SUICIDAL IDEATION CRISIS']},
      'LOSS FROM SUICIDE':{'critical': 0, 'facilities': [], 'resources': ['LOSS FROM SUICIDE','SUPPORT GROUPS FOR SUICIDAL IDEATION & LOSS FROM SUICIDE','GRIEF SUPPORT','GRIEF SUPPORT FOR CHILDREN & TEENS']},
      'PEER SUPPORT SERVICES': {'critical': 0, 'facilities': ['Mentoring/peer support'], 'resources': ['PEER SUPPORT SERVICES']},
      'FRIENDSHIP SUPPORT':{'critical': 0, 'facilities': [], 'resources': ['FRIENDSHIP SUPPORT']},
      "GROUP COUNSELING":{'critical': 0, 'facilities': ['Group counseling'], 'resources': []},
      'FAMILY RELATIONSHIP SUPPORT':{'critical': 0, 'facilities': ['Couples/family therapy'], 'resources': ['RELATIONSHIPS','FAMILY RELATIONSHIP SUPPORT']},
      "MARITAL/COUPLES COUNSELING":{'critical': 0, 'facilities': ['Marital/couples counseling'], 'resources': ['RELATIONSHIPS','FAMILY RELATIONSHIP SUPPORT', 'ROMANTIC', 'ROMANTIC RELATIONSHIP HELP']},
      'EMPLOYMENT':{'critical': 0, 'facilities': ['Employment counseling or training'], 'resources': ['EMPLOYMENT','FINDING A JOB','WORKPLACE MENTAL HEALTH','RESILIENCE-BUILDING']},
      'GRIEF SUPPORT':{'critical': 1, 'facilities': [], 'resources': ['GRIEF SUPPORT','GRIEF SUPPORT FOR CHILDREN & TEENS','EMOTIONAL SUPPORT']},
      "SUICIDE PREVENTION":{'critical': 0, 'facilities': [], 'resources': []},
      'GUN VIOLENCE PREVENTION':{'critical': 0, 'facilities': [], 'resources': ['GUN VIOLENCE PREVENTION']},
      "CASE MANAGEMENT SERVICES":{'critical': 0, 'facilities': ['Case management service'], 'resources': []},
      "HOUSING SERVICES":{'critical': 1, 'facilities': ['Housing services'], 'resources': ['HOUSING','HOUSING DISCRIMINATION & COMPLAINTS','HOUSING EVICTION']},
      "EDUCATION SERVICES":{'critical': 0, 'facilities': ['Education services'], 'resources': []},
      'END-OF-LIFE TRANSITION':{'critical': 0, 'facilities': [], 'resources': ['END-OF-LIFE TRANSITION','LONG-TERM CARE PLANNING']},
      "TRANSPORTATION ASSISTANCE":{'critical': 0, 'facilities': ['Transportation assistance'], 'resources': ['TRANSPORTATION','TRANSPORTATION FOR ELDERLY']},
      "DIALECTICAL BEHAVIOR THERAPY":{'critical': 0, 'facilities': ['Dialectical behavior therapy'], 'resources': []},
      "COGNITIVE BEHAVIORAL THERAPY":{'critical': 0, 'facilities': ['Cognitive behavioral therapy'], 'resources': []},
      "ANGER MANAGEMENT SERVICES":{'critical': 0, 'facilities': ['Anger management'], 'resources': []},
      "THERAPEUTIC FOSTER CARE SERVICES":{'critical': 0, 'facilities': [], 'resources': []},
      "ELECTROCONVULSIVE THERAPY":{'critical': 0, 'facilities': [], 'resources': []},
      "EYE MOVEMENT DESENSITIZATION AND REPROCESSING THERAPY":{'critical': 0, 'facilities': [], 'resources': []},
      "COGNITIVE REMEDIATION THERAPY":{'critical': 0, 'facilities': [], 'resources': []},
      "KETAMINE INFUSION THERAPY":{'critical': 0, 'facilities': [], 'resources': []},
      "TRANSCRANIAL MAGNETIC STIMULATION THERAPY":{'critical': 0, 'facilities': [], 'resources': []},
      "DRUG OR ALCOHOL URINE SCREENING":{'critical': 0, 'facilities': ['Drug or alcohol urine screening'], 'resources': []},
      "NICOTINE REPLACEMENT THERAPY":{'critical': 0, 'facilities': ['Nicotine replacement'], 'resources': []},
      "SMOKING/VAPING/TOBACCO CESSATION COUNSELING SERVICE":{'critical': 0, 'facilities': ['Smoking/vaping/tobacco cessation counseling'], 'resources': []},
      "DETOXIFICATION SERVICES":{'critical': 0, 'facilities': ['Detoxification'], 'resources': []},
      "TUBERCULOSIS SCREENING":{'critical': 0, 'facilities': ['TB screening'], 'resources': []},
      "STD TESTING":{'critical': 0, 'facilities': ['STD testing'], 'resources': []},
      "METABOLIC SYNDROME MONITORING":{'critical': 0, 'facilities': ['Metabolic syndrome monitoring'], 'resources': []},
      "HEPATITIS B (HBV) TESTING":{'critical': 0, 'facilities': ['Testing for Hepatitis B (HBV)'], 'resources': []},
      "HEPATITIS C (HCV) TESTING":{'critical': 0, 'facilities': ['Testing for Hepatitis C (HCV)'], 'resources': []},
      "TOBACCO SCREENING":{'critical': 0, 'facilities': ['Smoking/vaping/tobacco cessation counseling'], 'resources': []},
      'ANIMALS (EMOTIONAL SUPPORT & SERVICE DOGS)':{'critical': 0, 'facilities': [], 'resources': ['ANIMALS (EMOTIONAL SUPPORT & SERVICE DOGS)']},
      'MENTAL HEALTH POLICY/SELF-ADVOCACY':{'critical': 0, 'facilities': [], 'resources': ['MENTAL HEALTH POLICY/SELF-ADVOCACY','PATIENT ADVOCATE SERVICES']},
      'PATIENT ADVOCATE SERVICES':{'critical': 0, 'facilities': [], 'resources': ['MENTAL HEALTH POLICY/SELF-ADVOCACY','PATIENT ADVOCATE SERVICES']},
      "LGBTQ": {'critical': 1, 'facilities': ['Lesbian, gay, bisexual, transgender, or queer/questioning (LGBTQ)'], 'resources': ['LGBTQI COMMUNITY']},
      'TRANSGENDER': {'critical': 1, 'facilities': ['Lesbian, gay, bisexual, transgender, or queer/questioning (LGBTQ)'], 'resources': ['LGBTQI COMMUNITY','TRANSGENDER RESOURCES']},
      "VETERANS": {'critical': 1, 'facilities': ['Veterans'], 'resources': ['VETERAN CRISIS SUPPORT','VETERANS/MILITARY']},
      "ACTIVE DUTY MILITARY": {'critical': 1, 'facilities': ['Active duty military'], 'resources': ['VETERANS/MILITARY']},
      "MILITARY FAMILIES": {'critical': 1, 'facilities': ['Members of military families'], 'resources': ['VETERANS/MILITARY']},
      "PREGNANT/POSTPARTUM WOMEN": {'critical': 1, 'facilities': ['Pregnant/postpartum women'], 'resources': []},
      'DISABILITY RESOURCES': {'critical': 1, 'facilities': [], 'resources': ['DISABILITY BENEFITS (INCLUDING SSI & SSDI)','DISABILITY RIGHTS','DISABILITY EDUCATION','DISABILITY EMPLOYMENT/ACCOMMODATIONS','DISABILITY HOUSING DISCRIMINATION/COMPLAINTS','PHYSICAL DISABILITY','INTELLECTUAL DISABILITIES']},
      'INCARCERATED PERSONS': {'critical': 1, 'facilities': ['Criminal justice (other than DUI/DWI)/Forensic clients'], 'resources': ['INCARCERATED PERSONS','APPEALING SENTENCE FOR INCARCERATED PERSONS','MISTREATMENT WHILE INCARCERATED','REENTRY AFTER A PERIOD OF INCARCERATION']},
      'LAW ENFORCEMENT COMMUNITY': {'critical': 1, 'facilities': [], 'resources': ['LAW ENFORCEMENT COMMUNITY']},
      'HEALTHCARE & FRONTLINE WORKERS': {'critical': 1, 'facilities': [], 'resources': ['HEALTHCARE & FRONTLINE WORKERS']},
      "HIV OR AIDS": {'critical': 1, 'facilities': ['Clients with HIV or AIDS','HIV testing'], 'resources': []},
      "TRAUMA": {'critical': 1, 'facilities': ['Clients who have experienced trauma'], 'resources': ['TRAUMA']},
      'DEPRESSION': {'critical': 1, 'facilities': [], 'resources': ['DEPRESSION']},
      "ALZHEIMER'S OR DEMENTIA": {'critical': 1, 'facilities': ["Persons with Alzheimer's or dementia"], 'resources': ['ALZHEIMER’S/DEMENTIA']},
      "EATING DISORDERS": {'critical': 1, 'facilities': ['Persons with eating disorders'], 'resources': ['EATING DISORDERS']},
      'ANOSOGNOSIA': {'critical': 1, 'facilities': [], 'resources': ['ANOSOGNOSIA']},
      'ANXIETY DISORDERS': {'critical': 1, 'facilities': [], 'resources': ['ANXIETY DISORDERS']},
      'AUTISM SPECTRUM DISORDER (ASD)': {'critical': 1, 'facilities': [], 'resources': ['AUTISM SPECTRUM DISORDER (ASD)']},
      'BIPOLAR DISORDER (BD)': {'critical': 1, 'facilities': [], 'resources': ['BIPOLAR DISORDER (BD)']},
      'BRAIN INJURY': {'critical': 1, 'facilities': ['Persons with traumatic brain injury (TBI)'], 'resources': ['BRAIN INJURY']},
      'DISSOCIATIVE DISORDERS': {'critical': 1, 'facilities': [], 'resources': ['DISSOCIATIVE DISORDERS']},
      'PERSONALITY DISORDERS': {'critical': 1, 'facilities': [], 'resources': ['PERSONALITY DISORDERS','BORDERLINE PERSONALITY DISORDER','NARCISSISTIC PERSONALITY DISORDER']},
      'POST-TRAUMATIC STRESS DISORDER (PTSD)': {'critical': 1, 'facilities': ['Persons with post-traumatic stress disorder (PTSD)'], 'resources': ['POST-TRAUMATIC STRESS DISORDER (PTSD)','TRAUMA']},
      'PSYCHOSIS': {'critical': 1, 'facilities': ['Persons experiencing first-episode psychosis'], 'resources': ['FIRST EPISODE PSYCHOSIS (FEP)','PSYCHOSIS']},
      "CHILDREN/ADOLESCENTS WITH SERIOUS EMOTIONAL DISTURBANCE (SED)": {'critical': 1, 'facilities': ['Children/adolescents with serious emotional disturbance (SED)'], 'resources': []},
      'OBSESSIVE-COMPULSIVE DISORDER (OCD)': {'critical': 1, 'facilities': [], 'resources': ['OBSESSIVE-COMPULSIVE DISORDER (OCD)']},
      'OPPOSITIONAL DEFIANT DISORDER (ODD)': {'critical': 1, 'facilities': [], 'resources': ['OPPOSITIONAL DEFIANT DISORDER (ODD)']},
      'SELF-INJURY': {'critical': 1, 'facilities': [], 'resources': ['SELF-INJURY']},
      'SLEEP DISORDERS': {'critical': 1, 'facilities': [], 'resources': ['SLEEP DISORDERS']},
      'SCHIZOPHRENIA': {'critical': 1, 'facilities': [], 'resources': ['SCHIZOPHRENIA SPECTRUM & OTHER PSYCHOTIC DISORDERS']},
      'SUBSTANCE USE DISORDERS': {'critical': 1, 'facilities': ['Substance use treatment'], 'resources': ['SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      'ALCOHOLISM': {'critical': 1, 'facilities': ['Substance use treatment'], 'resources': ['SUBSTANCE RELATED & ADDICTIVE DISORDERS','ALCOHOLISM']},
      'COMPULSIVE SEXUAL BEHAVIOR': {'critical': 1, 'facilities': [], 'resources': ['COMPULSIVE SEXUAL BEHAVIOR','SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      'GAMBLING ADDICTION': {'critical': 1, 'facilities': ['Treatment for gambling disorder'], 'resources': ['GAMBLING ADDICTION','SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      'INTERNET & GAMING ADDICTION': {'critical': 1, 'facilities': [], 'resources': ['INTERNET & GAMING ADDICTION','SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      'NARCOTICS ADDICTION': {'critical': 1, 'facilities': ['Substance use disorder counseling'], 'resources': ['NARCOTICS ADDICTION','SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      "DOMESTIC VIOLENCE": {'critical': 1, 'facilities': ['Domestic violence services, including family or partner'], 'resources': ['ABUSE','DOMESTIC VIOLENCE & SEXUAL ASSAULT']},
      "SEXUAL ABUSE": {'critical': 1, 'facilities': ['Clients who have experienced sexual abuse'], 'resources': ['ABUSE','DOMESTIC VIOLENCE & SEXUAL ASSAULT']},
      'HOARDING': {'critical': 1, 'facilities': [], 'resources': ['HOARDING','SUBSTANCE RELATED & ADDICTIVE DISORDERS']},
      'CHILD ABUSE':{'critical': 1, 'facilities': [], 'resources': ['ABUSE','CHILD ABUSE']},
      'ELDER ABUSE':{'critical': 1, 'facilities': [], 'resources': ['ABUSE','ELDER ABUSE']},
      'ONLINE ABUSE':{'critical': 1, 'facilities': [], 'resources': ['ABUSE','ONLINE ABUSE']},
      "REPRODUCTIVE HEALTH":{'critical': 1, 'facilities': [], 'resources': ["REPRODUCTIVE HEALTH RELATED - MENTAL HEALTH"]},
      'MISSING PERSONS':{'critical': 1, 'facilities': [], 'resources': ['MISSING PERSONS']},
      'LONG COVID':{'critical': 1, 'facilities': [], 'resources': ['LONG COVID RESOURCES']},
      'ATTENTION DEFICIT/HYPERACTIVITY DISORDER (ADHD)':{'critical': 1, 'facilities': [], 'resources': ['ATTENTION DEFICIT/HYPERACTIVITY DISORDER (ADHD)']},
      "LEGAL ADVOCACY":{'critical': 0, 'facilities': ['Legal advocacy'], 'resources': ['LOW-COST/PRO BONO (FREE) LEGAL SERVICES']},
      'LOW-COST/PRO BONO (FREE) LEGAL SERVICES':{'critical': 1, 'facilities': [], 'resources': ['LOW-COST/PRO BONO (FREE) LEGAL SERVICES']},
      'LEGAL GUARDIANSHIP & CONSERVATORSHIP':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['LOW-COST/PRO BONO (FREE) LEGAL SERVICES','LEGAL GUARDIANSHIP & CONSERVATORSHIP']},
      'IMMIGRATED & UNDOCUMENTED PERSONS':{'critical': 1, 'facilities': [], 'resources': ['IMMIGRATED & UNDOCUMENTED PERSONS']},
      'COMPLAINTS AGAINST A CORRECTIONAL FACILITY':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['COMPLAINTS AGAINST A CORRECTIONAL FACILITY','LOW-COST/PRO BONO (FREE) LEGAL SERVICES']},
      'COMPLAINTS AGAINST A HOUSING FACILITY':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['COMPLAINTS AGAINST A HOUSING FACILITY','LOW-COST/PRO BONO (FREE) LEGAL SERVICES']},
      'COMPLAINTS AGAINST A TREATMENT FACILITY OR HOSPITAL':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['COMPLAINTS AGAINST A TREATMENT FACILITY OR HOSPITAL','LOW-COST/PRO BONO (FREE) LEGAL SERVICES']},
      'ACCOMMODATIONS & DISCRIMINATION ISSUES':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['ACCOMMODATIONS & DISCRIMINATION ISSUES']},
      'INVOLUNTARY & CIVIL COMMITMENT':{'critical': 1, 'facilities': ['Legal advocacy'], 'resources': ['INVOLUNTARY & CIVIL COMMITMENT']},
      "PAYMENT OR INSURANCE ASSISTANCE":{'critical': 0, 'facilities': ['Payment assistance (check with facility for details)'], 'resources': ['FINANCIAL ASSISTANCE','AFFORDABLE TREATMENT (NO INSURANCE OR PUBLIC INSURANCE)','INSURANCE COVERAGE & COMPLAINTS','MEDICAL CARE & HOSPITAL BILLS','FINANCIAL ASSISTANCE FOR PRESCRIPTION MEDICATION','HEALTH INSURANCE PORTABILITY & ACCOUNTABILITY ACT (HIPAA)','INSURANCE']},
      'PRIVATE INSURANCE ACCEPTED':{'critical': 1, 'facilities': ['Private health insurance'], 'resources': ['PRIVATE INSURANCE']},
      "MEDICARE INSURANCE ACCEPTED":{'critical': 1, 'facilities': ['Medicare'], 'resources': ['FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','INSURANCE']},
      "MEDICAID (OR MEDI-CAL) INSURANCE ACCEPTED":{'critical': 1, 'facilities': ['Medicaid'], 'resources': ['FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','INSURANCE']},
      "FEDERAL MILITARY INSURANCE (E.G., TRICARE) ACCEPTED":{'critical': 1, 'facilities': ['Federal military insurance (e.g., TRICARE)'], 'resources': ['FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','INSURANCE']},
      "CASH OR SELF-PAYMENT ACCEPTED":{'critical': 1, 'facilities': ['Cash or self-payment'], 'resources': ['FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','INSURANCE']},
      "U.S. DEPARTMENT OF VA FUNDS PAYMENT/INSURANCE ACCEPTED":{'critical': 1, 'facilities': ['U.S. Department of VA funds'], 'resources': ['FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','INSURANCE']},
      'AFFORDABLE TREATMENT (NO INSURANCE OR PUBLIC INSURANCE)':{'critical': 1, 'facilities': [], 'resources': ['AFFORDABLE TREATMENT (NO INSURANCE OR PUBLIC INSURANCE)','FINANCIAL ASSISTANCE','MEDICAL CARE & HOSPITAL BILLS','FINANCIAL ASSISTANCE FOR PRESCRIPTION MEDICATION','HEALTH INSURANCE PORTABILITY & ACCOUNTABILITY ACT (HIPAA)','INSURANCE','INSURANCE COVERAGE & COMPLAINTS']},
      'PRESCRIPTION MEDICATION FINANCIAL ASSISTANCE': {'critical': 1, 'facilities': [], 'resources': ['FINANCIAL ASSISTANCE','PRESCRIPTION MEDICATION FINANCIAL ASSISTANCE','MEDICATION']},
      "SIGN LANGUAGE SERVICES FOR DEAF AND HARD OF HEARING":{'critical': 1, 'facilities': ['Sign language services for the deaf and hard of hearing'], 'resources': []},
      "SPANISH LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['Spanish'], 'resources': []},
      "CHINESE LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['Any Chinese Language'], 'resources': []},
      "FRENCH LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['French'], 'resources': []},
      "ITALIAN LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['Italian'], 'resources': []},
      "GERMAN LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['German'], 'resources': []},
      "JAPANESE LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['Japanese'], 'resources': []},
      "KOREAN LANGUAGE TRANSLATION SERVICE":{'critical': 1, 'facilities': ['Korean'], 'resources': []},
      'RESEARCH/ACADEMIA FOR MENTAL HEALTH':{'critical': 1, 'facilities': [], 'resources': ['RESEARCH/ACADEMIA FOR MENTAL HEALTH']},
      'INTERNATIONAL MENTAL HEALTH RESOURCES':{'critical': 1, 'facilities': [], 'resources': ['INTERNATIONAL MENTAL HEALTH RESOURCES']}
      }
      


    // Define options arrays
    const generalOptions = Object.keys(label_mapping); // used to be values with the old label_mapping

    // always refresh by running the filter search api to get the services to display
    useEffect(() => {
      if (countySelection.length > 0 && Object.keys(geminiClassifierResults).length !== 0) {

          const allFacilities_tags = [];
          const allResources_tags = [];
          // const allCritical_tags = []
          selectedGeminiResults.forEach(label => {
              const facilities = label_mapping[label]['facilities'];
              const resources = label_mapping[label]['resources'];
              // const criticality = label_mapping[label]['critical']
              // Check if facilities and resources are arrays, then concatenate them
              if (Array.isArray(facilities)) {
                facilities.forEach(facility => {
                    if (!allFacilities_tags.includes(facility)) {
                        allFacilities_tags.push(facility);
                    }
                });
            }
        
            // Check if resources is an array and add unique elements to allResources_tags
            if (Array.isArray(resources)) {
                resources.forEach(resource => {
                    if (!allResources_tags.includes(resource)) {
                        allResources_tags.push(resource);
                    }
                });
            }

         


          });

      
          // const inputs = selectedGeminiResults;

          // const gemini_results = geminiClassifierResults.map((label) => label_mapping[label])
  
          const fetchData = async () => {
              setIsLoading(true);           
              try {
                  const response = await fetch('/filtersearch_gemini', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ userInput, selectedGeminiResults, allFacilities_tags, allResources_tags, stateSelection, countySelection })
                  });
  
                  if (response.ok) {
                      const data = await response.json();
                      const { facilities_results, resources_results } = data

                   
                      setSearchResults(facilities_results);
                      setFacilitiesSearchResults(facilities_results)
                      setResourcesSearchResults(resources_results)


                  } else {
                      throw new Error('Network response was not ok');
                  }
              } catch (error) {
                  console.error('Error:', error);
              }
              setIsLoading(false);
          };
  
          fetchData();
      }
  }, [countySelection, selectedGeminiResults]); // Run effect when countySelection or classifierResults change
  


    useEffect(() => {
        const anchor = document.getElementById('anchor');
        if (anchor && selectedGeminiResults.length > 0) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }, [searchResults]);
    

    const handleCountySelectionChange = (selectedCounties) => {
        setCountySelection(selectedCounties);
    };

    const handleStateSelectionChange = (selectedState) => {
      setStateSelection(selectedState);

      const getCounties = async () => {
        setIsLoading(true);           
        try {
            const response = await fetch('/getCountiesfromState', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ selectedState })
            });

            if (response.ok) {
                const data = await response.json();
                
                setCountyOptionList(data);
                
                handleCountySelectionChange([data[0]])

            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        setIsLoading(false);
    };

    getCounties();


  };

   
    const handleUserInputChange = (event) => {
        setShowNoInputMessage(false)
        setUserInput(event.target.value);
    };

   

    const handleGeminiChange = (selectedOptions) => {
        if (typeof selectedOptions === 'string') {
            selectedOptions = [selectedOptions];
        }
        setSelectedGeminiResults(selectedOptions);
        
    }

    // when the user clicks, run the gemini api request 
    const handleSearchClick = async () => {
      if ((userInput.length > 0) && (!(countySelection.length == 0))) {
        setIsLoading(true);

        try {
          const geminiresponse = await fetch('/run_gemini', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ userInput })
          });

          if (geminiresponse.ok) {
              const geminiData = await geminiresponse.json();

              // saving an original copy of the gemini results
              setGeminiClassifierResults(geminiData);

              // save the data as the label mapping result
              await Promise.all([
                  handleGeminiChange(geminiData)
              ]);
          } else {
              throw new Error('Network response was not ok');
          }
      } catch (error) {
          console.error('Error:', error);
      }
      setIsLoading(false);
      } else {
        setShowNoInputMessage(true)

      }
      
  };

    

    function FacilitiesSearchResult({ results }) {
        const colorMap = {
            'Type of Care': '#005B9A', // Dark Blue
            'Service Setting (e.g., Outpatient, Residential, Inpatient, etc.)': '#ee7d72', // Reddish Orange
            'Treatment Approaches': '#65a4cf', // Light Blue
            // 'Payment/Insurance/Funding Accepted': 'darkgrey',// '#b48696', // purplish
            'Other': '#e4b24a' // Yellowish
        };

        const formatFacilityForEmail = (facility) => {
          let emailBody = `${facility.full_name}\n`;
          emailBody += `${facility.street1}, ${facility.city} ${facility.state} ${facility.zip}, ${facility.county} County\n`;
          emailBody += `${facility.website === 'http://' ? 'No website available' : facility.website} | ${facility.phone}\n\n`;
  
          Object.keys(colorMap).forEach(categoryKey => {
              if (facility.tags_of_interest[categoryKey]) {
                  emailBody += `${categoryKey}:\n`;
                  facility.tags_of_interest[categoryKey].forEach((item) => {
                      emailBody += `- ${item}\n`;
                  });
                  emailBody += '\n';
              }
          });
  
          return encodeURIComponent(emailBody);
      };
  

        return (
          <div className="SearchResult">
            
            {results.map((facility, index) => (
              <div className='facility-card' key={index}>
                <div className='internal-facility-card'>
                  <div className='title'><strong>{facility.full_name}</strong></div>
                  {facility.street2 !== 0 ? (
                    <p className='address'>{facility.street1}, {facility.street2}, {facility.city} {facility.state} {facility.zip}, {facility.county} County</p>
                  ) : (
                    <p className='address'>{facility.street1}, {facility.city} {facility.state} {facility.zip}, {facility.county} County</p>
                  )}
                  {facility.website == "http://" ? (
                    <p className='contact'>No website available | {facility.phone}</p>
                  ) : (
                    <p className='contact'><a href={facility.website} target="_blank" rel="noopener noreferrer">{facility.website}</a> | {facility.phone}</p>
                  )}

                  {/* Rendering items_by_category */}
                  <div className='TagsOfInterestBox'>
                    {Object.keys(colorMap).map((categoryKey, catIndex) => (
                      <React.Fragment key={catIndex}> 
                        {facility.tags_of_interest[categoryKey].map((item, itemIndex) => (
                          <div key={itemIndex} style={{ backgroundColor: colorMap[categoryKey] }} className='TagOfInterest'>{item}</div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                  <button className="share-button">
                  <a href={`mailto:?subject=Search Result for ${facility.full_name}&body=${formatFacilityForEmail(facility)}`} target="_blank" rel="noopener noreferrer">
                      Share via email
                  </a>
              </button>
            </div>

            ))}
            
          </div>
        );
      }

      function ResourcesSearchResult({ results }) {
        const colorMap = {
            'Main Categories': '#ee7d72', // salmon pink
            'Extra Categories': '#e4b24a'//   Yellowish
            //  '#65a4cf' Light Blue
           
        };

        const formatResourcesForEmail = (facility) => {
          let emailBody = `${facility.full_name}\n`;
          emailBody += `${facility.website.join(', ')}\n\n`;
         
          emailBody += `${facility.desc}\n\n`;
      
          Object.keys(colorMap).forEach(categoryKey => {
            if (facility.tags_of_interest[categoryKey]) {
              emailBody += `${categoryKey}:\n`;
              facility.tags_of_interest[categoryKey].forEach((item) => {
                emailBody += `- ${item}\n`;
              });
              emailBody += '\n';
            }
          });
  
          return encodeURIComponent(emailBody);
      };

        return (
          <div className="SearchResult">
            {results.map((facility, index) => (
              
              <div className='facility-card' key={index}>
                  <div className='internal-facility-card'>
                      <div className='title'><strong>{facility.full_name}</strong></div>

                      <div className='linkBox'>
                        {facility.website.map((item, itemIndex) => (
                            <p key={itemIndex} className='links'><a href={item} target="_blank" rel="noopener noreferrer">{item}</a></p>
                        ))}
                      </div>
                      
                      <div className='desc'>{facility.desc}</div>

                      {/* Rendering items_by_category */}
                      <div className='TagsOfInterestBox'>
                        {Object.keys(colorMap).map((categoryKey, catIndex) => (
                          <React.Fragment key={catIndex}> 
                            {facility.tags_of_interest[categoryKey].map((item, itemIndex) => (
                              <div key={itemIndex} style={{ backgroundColor: colorMap[categoryKey] }} className='TagOfInterest'>{item}</div>
                            ))}
                          </React.Fragment>
                        ))}
                      </div>

                </div>
                <button className="share-button">
                    <a href={`mailto:?subject=Search Result for ${facility.full_name}&body=${formatResourcesForEmail(facility)}`} target="_blank" rel="noopener noreferrer">
                        Share via email
                    </a>
                </button>
              </div>
            ))}
            
          </div>
        );
      }


      

      const [showInputs, setShowInputs] = useState(false);
      const handleDropdownClick = () => {
        setShowInputs(!showInputs);
      };

      const toggleFacilities = () => {
        if ((showFacilities) && (!showResources)) {
          setShowResources(true);
        }
        setShowFacilities(!showFacilities);
        
      };
    
      const toggleResources = () => {
        if ((showResources) && (!showFacilities)) {
          setShowFacilities(true);
        }
        
        setShowResources(!showResources);
        
      };

      

    
    
// edit the select boxes so that you can click multiple -- cuz right now im only doing one item, so need to force it into an array
return (
    <div className="App">
        <div className='SearchFormContainer'>
        {/* <div className='MiniContainer'> */}

        <div className="SearchForm">
            
            {/* SearchMentalHealth is the first nationwide AI-powered navigational tool for mental health services | Describe your situation and rapidly get matched with suitable local services */}
            {/* The first AI-powered navigational tool for mental health services | Describe your situation and rapidly get matched with appropriate local services */}
            {/* AI will instantly match you with appropriate local facilities and their services from SAMHSA and NAMI data */}
            {/* Describe your situation and AI will rapidly match you with appropriate local facilities and their services */}
      
              <div className="dropdown-container">
              {(!showInputs) && (
                <div>
                <h1 className='AppTitle'>Don't know what mental health services are available and right for you?</h1>
                <p className='AppSubtitle'>Instantly get matched with appropriate local facilities and their services</p> 
                <button className="dropdown-button" onClick={handleDropdownClick}>
                  <span className="dropdown-arrow">{showInputs ? '▼' : '► Start'}</span>
                   
                </button>
                </div>
              )}
                {showInputs && (
                  <div className="dropdown-content">
                    <h1 className='AppTitleTwo'>Describe your situation and instantly get matched with local mental health facilities and resources</h1>

      
                    <div className="help-container">
                      

                      <textarea
                        id="userInput"
                        value={userInput}
                        onChange={handleUserInputChange}
                        placeholder="I have a son in high school struggling with depression. He has not been diagnosed with anything, but I think he needs a psychiatrist or therapist. I'm on MediCal so I want to find services for my son that won't require me to pay anything out of pocket..."
                        className="userInput"
                      />
                      <span className="help-tooltip">?</span>
                        <div className="tooltip-content">
                          <p>Please be as descriptive as possible for optimal search results. Please share: who you are, what you're dealing with, and what you need.</p>
                        </div>

                    </div>
                    
                    <div className='flexBox'>
                          <div className='countyMessage'>State:</div>
                                    <State_MultiSelect
                                        placeholder="CA"
                                        onSelect={handleStateSelectionChange}
                                    />

                        <div className='countyMessage'>County:</div>
                        {/* <div className='addBorder'> */}
                          <div className='addPaddingtoCounty'>
                              <County_MultiSelect
                                  options={countyOptionList}
                                  placeholder="Add a county"
                                  selectedOptions={countySelection}
                                  onSelect={handleCountySelectionChange}
                              />
                          </div>

                    </div>
                    <button className='SearchFormButton' onClick={handleSearchClick}>Search</button>
                    <div>{showNoInputMessage && (<p className='noInputMessage'>Please share details about your situation and select a county above!</p>)}</div>
                  </div>
                )}
              </div>

            </div>
          </div>



        {isLoading ? (
                <div className='loading'>Loading...</div>
            ) : (
                <div id='anchor' className="SearchResults">

                {(countySelection.length > 0 && Object.keys(geminiClassifierResults).length !== 0) && (
                    <div className='SearchFilterBox'>
           
                        <div className='TagsContainer'>
                            <h3>Refine your AI-determined matching criteria</h3>
                            <div className='addPaddingtoCounty'>
                            <MultiSelect
                                options={generalOptions}
                                placeholder="Add another search filter"
                                selectedOptions={selectedGeminiResults}
                                onSelect={handleGeminiChange} />  
                            </div>
                        </div>   

                        <div className='horizontalLine'></div>

                        <div className='ResultsBox'>
                            <h3>Facilities and resources matched with your situation</h3>

                            <div className="toggle-buttons">
                                <p>Show Local Facilities</p>
                                <label className="toggle-switch">
                                
                                  <input type="checkbox" checked={showFacilities} onChange={toggleFacilities} />
                                  <span className="slider">
                                  </span>
                                </label>
                                <p>Show Online Resources</p>
                                <label className="toggle-switch">
                                  
                                  <input type="checkbox" checked={showResources} onChange={toggleResources} />
                                  <span className="slider">
                                  </span>
                                </label>
                              </div>
                            

                                                          {/* {(facilitiesSearchResults.length > 0 && showFacilities) ? (
                                <SearchResult results={facilitiesSearchResults} />
                              ) : (
                                <p>No facilities matched your criteria. Consider adjusting your search prompt or filters, or try searching beyond your county.</p>
                              )} */}
                              
                              {(facilitiesSearchResults.length > 0 && showFacilities) ? (
                                <FacilitiesSearchResult results={facilitiesSearchResults} />
                              ) : ( <p></p>)}
                              {(resourcesSearchResults.length > 0 && showResources) ? (
                                <ResourcesSearchResult results={resourcesSearchResults} />
                              ) : (<p></p> )}

                            {/* {facilitiesSearchResults.length > 0 ? <SearchResult results={facilitiesSearchResults} /> : <p>No search results matched your criteria. Consider adjusting your search prompt or filters, or try searching beyond your county.</p>}
                            {resourcesSearchResults.length > 0 ? <SearchResult results={resourcesSearchResults} /> : <p>No search results matched your criteria. Consider adjusting your search prompt or filters, or try searching beyond your county.</p>} */}

                            {(facilitiesSearchResults.length+resourcesSearchResults.length) > 0  && <p className='bottom-search-message'>Don't see any search results that match your criteria? Consider adjusting your search prompt or filters, or try searching beyond your county.</p>}
                            
                        </div>
                    </div>
                )}
                </div>
            )}

            <div className='footerMessagesContainer'>
            <TopArrowButton />

          
            <div className='footer-Icons'> 
              <a href="https://nami.org/" target="_blank"><img src='/nami-logo-blue.svg' alt="NAMI Logo" style={{ width: '110px', height: 'auto', marginLeft: '3vw', padding: '10px 0px' }} /></a>
              {/* <a href="https://mentalhealthlab.org/" target="_blank"><img src='mhi_rect.png' alt="NAMI Logo" style={{ width: '195px', height: 'auto', marginLeft: '3vw', padding: '10px 0px' }} /></a> */}
            </div>
              <div className='footerMessages'>
                  <p className='EmergencyWarning'>Please call or text <a href="tel:988">988</a> if you are in a crisis.</p>
                 
                  <p className='DataSources'>Powered by AI, <a href="https://www.namisf.org/resources" target="_blank">NAMI resource guides</a> & <a href="https://www.samhsa.gov/" target="_blank">SAMHSA</a> data |  <a href="https://forms.gle/xmwvhpsbzgp9f7ZW7" target="_blank" rel="noopener noreferrer"><strong>Have feedback or questions?</strong></a> | Created by <a href="https://charlotterosario.vercel.app" target="_blank">Charlotte Rosario</a></p>
                  
              </div>

             
            </div>
    </div>
  );

}

export default NewApp;

